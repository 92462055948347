import { ref, reactive, computed, onMounted, toRaw } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router'
import useFormatDate from "@/utils/payment/useFormatDate";
import {
  getProgramDealerStatuslist,
  getProgramDealerList,
  getDealerlist,
  getOverviewDealerlist,
  getOverviewProgramDealerList,
  getProgramOfferTypeList,
  getPackageCodeInfo
} from "@/API/checking/spotChecking";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
const useSpotCheckProgramDealer = () => {
  const route = useRoute()

  const column = ref([
    { title: "Program Code", width: 130, dataIndex: "programCode" },
    {
      title: "Program Name",
      width: 280,
      dataIndex: 'programName',
      slots: { customRender: "programName" },
    },
    { title: "Offer Type", dataIndex: "offerType", width: 160 },
    {
      title: "Program Period From to",
      dataIndex: "programPeriodFrom",
      width: 200,
      customRender: useFormatDate("YYYY-MM-DD"),
    },
    {
      title: "To",
      dataIndex: "programPeriodTo",
      customRender: useFormatDate("YYYY-MM-DD"),
      width: 90,
    },
    { title: "Dealer No.", dataIndex: "dealerNo", align: "right",width: 90 },
    { title: "Total VIN No.", dataIndex: "vinNo", align: "right",width: 100 },
    { title: "Claim No.", dataIndex: "claimNo", align: "right",width: 80 },
    {
      // title: "SIPO Confirmed Amount",
      title: "Claim Passed Amount",
      dataIndex: "sipoConfirmedAmount",
      customRender: useThousandsAndTwoDecimal(),
      width: 160,
      align: "right",
    },
    {
      title: "Spot Confirmed Amount",
      dataIndex: "spotConfirmedAmount",
      width: 180,
      customRender: useThousandsAndTwoDecimal(),
      align: "right",
    },
    { title: "Status", dataIndex: "status",width: 140 },
    ...(route.name === 'spotCheckConfirmProgramDealer' ? 
      [{ title: "Operation", slots: { customRender: "operation" }, width: 90, fixed: 'right' }] : [])
  ]);
  const { state } = useStore();
  const id = ref(state.spotChecking.spotCheckConfirmPackageDealer.id);
  const type = ref(state.spotChecking.spotCheckConfirmPackageDealer.type);
  const summary = ref<any>({});
  if (type.value === "checkedOverview") {
    getPackageCodeInfo(id.value).then((res) => {
      summary.value = res;
    });
  } else if (type.value === "overview") {
    getOverviewDealerlist({ id: id.value }).then((res) => {
      summary.value = res.data[0] ? res.data[0] : {};
    });
  } else {
    getDealerlist({ id: id.value }).then((res) => {
      summary.value = res.data[0] ? res.data[0] : {};
    });
  }

  const timeLine = computed(() => {
    return [summary.value.timeLineFrom, summary.value.timeLineTo];
  });
  const status = ref("");
  const statusRes = ref();
  const noShowStatus = ["all", "Controlling Confirmed", "Submit For Checking"];
  const statusList = computed(() => {
    const arr = [];
    for (const key in statusRes.value) {
      if (noShowStatus.indexOf(statusRes.value[key]) === -1) {
        arr.push({
          name: statusRes.value[key],
          value: key,
        });
      }
    }
    return arr;
  });
  const getStatusList = () => {
    getProgramDealerStatuslist().then((res) => {
      statusRes.value = res;
    });
  };
  const data = ref([]);
  const isAllControllingReviewed = computed(() => {
    if (data.value.length === 0) {
      return false;
    }
    return data.value.every((item: any) => {
      return item.status === "Controlling Reviewed";
    });
  });
  const isAllConfirmToSipo = ref(true);
  const getTableData = () => {
    const _status = status.value ? status.value : "-1";
    if (type.value === 'checkedOverview') {
      getProgramOfferTypeList(id.value, _status).then((res: any) => {
        data.value = res;
      })
    } else if (type.value === "overview") {
      getOverviewProgramDealerList(id.value, _status).then((res: any) => {
        data.value = res?.list ?? [];
        isAllConfirmToSipo.value = res.isAllConfirmToSipo;
      });
    } else {
      getProgramDealerList(id.value, _status).then((res: any) => {
        data.value = res?.list ?? [];
        isAllConfirmToSipo.value = res.isAllConfirmToSipo;
      });
    }
  };
  return {
    data,
    column,
    summary,
    timeLine,
    status,
    id,
    statusList,
    isAllConfirmToSipo,
    getStatusList,
    getTableData,
    isAllControllingReviewed,
  };
};
export default useSpotCheckProgramDealer;
