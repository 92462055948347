
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";
import { FileSearchOutlined } from "@ant-design/icons-vue";
import useSpotCheckProgramDealer from "@/hooks/checking/spotChecking/checkingConfirm/useSpotCheckProgramDealer";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import {
  fetchDealerConfirm,
  fetchDealerReturn,
  fetchConfirmtoSIPO,
  downloadWordingFile,
  dealerVerifyReturn,
  dealerVerifyConfirm,
  dealerAddComments2,
  getCheckBeforeConfirm,
  getBatchConfirmResultList,
  confirmBatch,
} from "@/API/checking/spotChecking";
import ReturnConfirmModal from "../components/returnModalConfirm.vue";
import downloadFile from "@/utils/payment/downloadFile";
import GetColumnWidth from "@/utils/payment/getColumnWidth";
import ConfirmModal from "../components/confirmModal.vue";
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const {
      data,
      column,
      summary,
      timeLine,
      id,
      status,
      statusList,
      getStatusList,
      getTableData,
      isAllConfirmToSipo,
      isAllControllingReviewed,
    } = useSpotCheckProgramDealer();
    const search = () => {
      getTableData();
    };
    const reset = () => {
      status.value = "";
      getTableData();
    };
    const returnModalVisible = ref(false);
    const returnCommentModalVisible = ref(false);
    const handleReturn = async () => {
      const result = await dealerVerifyReturn([id.value]);
      if (!result.data) {
        // message.warning(result.message);
      } else {
        returnModalVisible.value = true;
      }
    };
    const returnVisible = ref(false);
    const pageComments = ref("");
    const handleCloseReturnVisible = () => {
      returnVisible.value = false;
      pageComments.value = "";
    };
    const handleReturnConfirm = async (comment: string) => {
      if (comment.length === 0) {
        message.error("Reason is required");
        return;
      }
      await dealerAddComments2(comment, [id.value]);
      await fetchDealerReturn([id.value]);
      search();
      message.success("Return successfully!");
      returnCommentModalVisible.value = false;
    };
    // const handleConfirm = async (isAddCom: any) => {
    //   if (pageComments.value.length === 0 && isAddCom) {
    //     message.error("Reason is required");
    //   } else {
    //     if (isAddCom) {
    //       dealerAddComments2(pageComments.value, [id.value]);
    //     }
    //     const result = await dealerVerifyConfirm([id.value]);
    //     if (!result.data) {
    //       // message.warning(result.message);
    //       return;
    //     }
    //     fetchDealerConfirm([id.value]).then((res) => {
    //       search();
    //       message.success("Confirm successfully!");
    //     });
    //     handleCloseReturnVisible();
    //   }
    // };
    // const isConfirm = ()=>{
    //   const dealerIdArr=ref<any>([])
    //   data.value.forEach((i: any,ind: any)=>{
    //     dealerIdArr.value.push(i.id)
    //   })
    //   const params = {
    //     ids:[id.value],
    //   }
    //   getCheckBeforeConfirm(params).then(res=>{
    //     console.log(320,res)
    //     if (res){
    //       returnVisible.value=true
    //     } else {
    //       handleConfirm(false)
    //     }
    //   })
    // }
    const handleConfirmSIPO = async () => {
      const res = await fetchConfirmtoSIPO([id.value]);
      if (res.code === "0") {
        search();
        message.success("ConfirmSIPO successfully!");
      }
    };
    const router = useRouter();
    const { commit } = useStore();
    const handleGoTo = (record: any) => {
      commit("spotChecking/updateSpotCheckConfirmProgramDealer", record);
      router.push({ path: "/spotCheckingControlling/dealerVinView" });
    };
    const init = () => {
      getStatusList();
      getTableData();
    };
    init();
    const handleExport = (record: any) => {
      const params = {
        url: `/claimapi/spotCheckConfirm/dealer/exportExcel?programOfferTypeId=${record.offerTypeId}`,
        method: "post",
      };
      downloadFile(params);
    };
    const handleExportWordingFile = (record: any) => {
      downloadWordingFile(record.offerTypeId).then((data: any) => {
        if (data.zipDownloadUrl) {
          window.location.href = data.zipDownloadUrl;
        }
      });
    };
    const tableWidth = ref(GetColumnWidth(column));

    const confirmVisible = ref(false);

    // confirm 前的校验
    const checkBeforeConfirm = () => {
      const params = {
        ids: [id.value],
      };
      return getCheckBeforeConfirm(params);
    };

    // confirn modal 里要展示的数据
    const confirmModalData = ref<any>([]);
    // 获取数据接口
    const getBatchConfirmData = () => {
      getBatchConfirmResultList(id.value).then((res: any) => {
        confirmModalData.value = res;
        confirmVisible.value = true;
      });
    };

    // Batch Submit 点击事件
    const handleBatchConfirmClick = async () => {
      // 校验
      const checkBeforeConfirmResult = await checkBeforeConfirm();
      if (checkBeforeConfirmResult) {
        // 如果为 true， 需要展示 returnVisible.value ?
        returnVisible.value = true;
      } else {
        // false 获取表格数据 并展示
        getBatchConfirmData();
      }
    };

    const handleConfirmReturnVisible = async () => {
      if (pageComments.value.length === 0) {
        message.error("Reason is required");
        return;
      }
      dealerAddComments2(pageComments.value, [id.value]);
      // const result = await dealerVerifyConfirm([id.value]);
      // if (!result.data) {
      //   return;
      // }
      getBatchConfirmData();
    };

    const confirmCancel = () => {
      confirmModalData.value = [];
    };

    // 弹框里的 comfirm 事件， 有个新接口 怎么调用有待考量
    const confirmSuccess = async (programOfferTypeIds: any) => {
      const result = await dealerVerifyConfirm([id.value]);
      if (!result.data) {
        return;
      }
      confirmBatch(programOfferTypeIds).then((res: any) => {
        search();
      });
    };

    return {
      handleCloseReturnVisible,
      // isConfirm,
      pageComments,
      returnVisible,
      data,
      column,
      summary,
      timeLine,
      status,
      statusList,
      isPagination: false,
      search,
      reset,
      handleGoTo,
      // handleConfirm,
      handleReturn,
      handleReturnConfirm,
      returnModalVisible,
      returnCommentModalVisible,
      handleConfirmSIPO,
      isAllConfirmToSipo,
      isAllControllingReviewed,
      handleExport,
      handleExportWordingFile,
      tableWidth,
      handleBatchConfirmClick,
      confirmModalData,
      confirmVisible,
      confirmSuccess,
      confirmCancel,
      handleConfirmReturnVisible,
    };
  },
  components: {
    TableWrapper,
    // FileDoneOutlined,
    FileSearchOutlined,
    ReturnConfirmModal,
    ConfirmModal,
  },
});
